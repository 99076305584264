import React, {Component} from 'react';
import axios from 'axios'
import './stylesheets/FMCallsignDetail.css'
import StationTable from './StationTable'
import { ScaleLoader } from 'react-spinners';



class NetworkDetail extends React.Component  {
	
	constructor(props){
		super(props)
		this.state = {
			name: '',
			urlname: '',
			stations_count: '',
			stations: [],
			loading: true,
		}
		this.sortBy = this.sortBy.bind(this)
	}


	
	
	componentDidMount(){
		const {networkurl} = this.props.match.params
		console.log('network', networkurl)
		axios.get('https://codesapi.dra.com.au/api/network/'+networkurl+'/',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				this.setState( res.data );
				this.setState({loading:false})
		}).catch(function(error){
			window.location = '/signin'
		})
	}

	sortBy(key){
		var sortedStation = [].concat(this.state.stations).sort((a,b) => a[key] > b[key])
		this.setState( {stations: sortedStation })

	}
	
	render(){
		return (
			<div>
				<div className="card">
					  <div className="card-header">
						<h1>{this.state.name}</h1><ScaleLoader
								className={{position: "relative"}}
								sizeUnit={"px"}
								size={50}
								color={'#000'}
								loading={this.state.loading}
								/>
					  </div>
					  <div className="card-body">
						<dl className="dl-horizontal card-text">
						  <dt>Station count:</dt>
						  <dd>{this.state.stations_count}</dd>

						</dl>
						
					  </div>
				</div>
				
				<StationTable stations={this.state.stations} sortBy={this.sortBy}/>
				
			</div>
		
		)
		
			
	}
	
}

export default NetworkDetail