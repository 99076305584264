import React from 'react';
import axios from 'axios'



import './stylesheets/FMCallsignDetail.css'

var apiURL = 'https://codesapi.dra.com.au/'


const FrequencyUnits = ({frequency}) => {
	if (frequency > 88.5 && frequency < 230)
	{
		return `${frequency} MHz`
	}else{
		return `${frequency} kHz`
	}
}




const SiteTable = (props) => {

	let rows =<tr> </tr>
	const results = props.sites
	console.log('sites',results)
	if (results !== 'undefined'){
		rows = results.map((r,index)=>{
			return(
					
					<tr key={index}>
						<td>{r.site_name}</td>
						<td>{r.area_served}</td>
						<td>{r.licence_area}</td>
						<td><FrequencyUnits frequency={r.frequency}/></td>
						<td>{r.maximum_erp}</td>
						<td>{r.purpose}</td>
						<td>{r.state}</td>
						<td>{r.licence_number}</td>
						<td>{r.site_id}</td>
						
					</tr>

			)
		
		})
	}
	return (
		<div>
			<h2> Site Details </h2>
			<table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Area served</th>
						<th scope="col">Licence Area</th>
						<th scope="col">Frequency</th>
						<th scope="col">Power (W)</th>
						<th scope="col">Type</th>	
						<th scope="col">State</th>	
						<th scope="col">Licence</th>		
						<th scope="col">Site</th>			
					</tr>
				</thead>
				<tbody key='index'>
					{rows}
				</tbody>
			</table>
		</div>
	
	)
	

}

const FMParameters = (props) => {
	
	
	let rows =<dl className="dl-horizontal"> </dl>
	const results = props.fmparameter
	if (results !== 'undefined'){
		rows = results.map((r,index)=>{
			return(
					<div>
					  <dt>Frequency:</dt>
					  <dd>{(r.frequency=== '')? "Unknown":r.frequency}</dd>	
					  <dt >Live PI code:</dt>
					  <dd>{(r.picodecurrent=== '')? "Unknown":r.picodecurrent}</dd>
					  <dt>Recommend PI code:</dt>
					  <dd>{r.picodegen}</dd>
					</div>
			)
		
		})
	}
	
	return(
		<dl className="dl-horizontal"> 
			{
				(rows== '')? "No Information Available":rows
			} 
		</dl>
		
	)
	
}

const DABParameters = (props) => {
	let rows =<dl className="dl-horizontal"> </dl>
	const results = props.dabparameter
		if (results !== 'undefined'){
			rows = results.map((r,index)=>{
				return(
					
					<div>
						<dt>Frequency:</dt>
						<dd>{(r.frequency=== '')? "Unknown":r.frequency}</dd>
						<dt >Ensemble Id:</dt>
						<dd>{(r.eid=== '')? "Unknown":(r.eid).toString(16).toUpperCase()}</dd>
						<dt >Service Id:</dt>
						<dd>{(r.sid=== '')? "Unknown":(r.sid).toString(16).toUpperCase()}</dd>
						<dt >Subchannel Id:</dt>
						<dd>{(r.subch=== '')? "Unknown":r.subch}</dd>
						
					</div>
				)
			})
		}
	

	return(
		<dl className="dl-horizontal"> 
			
			{
				(rows== '')? "No Information Available":rows
			} 
		</dl>
		
	)

}


class CallsignDetail extends React.Component  {
	
	

	state = {
		sites_two:[],
		fmparameter: [],
		dabparameter: []
	}

	
	
	componentDidMount(){
		const {callsign,id} = this.props.match.params
		console.log('callsign', callsign)
		axios.get(apiURL+'api/stationdetail/'+callsign +'/',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				this.setState( res.data );
		})
	}
	render(){
		return (
			<div>
				<div className="card">
					  <div className="card-header">
						<h1>{this.state.callsign}</h1>
					  </div>
					  <div className="card-body">
						<dl className="dl-horizontal card-text">
						  <dt>ACMA On Air ID:</dt>
						  <dd>{this.state.name}</dd>
						  <dt>Network:</dt>
						  <dd>{this.state.network_name}</dd>

						</dl>
						<div class="row">
							<div class="col-sm-6">
								<div class="card">
      								<div class="card-body">
									 	 <h5 class="card-title">FM Parameters</h5>
										  <p class="card-text">
												<FMParameters fmparameter={this.state.fmparameter}/>
										 </p>
									</div>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="card">
      								<div class="card-body">
									  <h5 class="card-title">DAB Parameters</h5>
									  	<p class="card-text">
											<DABParameters dabparameter={this.state.dabparameter}/>
										</p>
									</div>
								</div>
							</div>
						</div>
					  </div>
				</div>
				
				<SiteTable sites={this.state.sites_two}/>
				
				
			</div>
			
		
		)
	}

}


export default CallsignDetail;