import React, {Component} from 'react';
import axios from 'axios';

//let letapiPosturl = 'http://localhost:9000/api/'+'contactus/'
let letapiPosturl = 'https://codesapi.dra.com.au/api/'+'contactus/'
var ReactSafeHtml = require('react-safe-html');

class Contact extends React.Component{
    constructor(props){
        super(props)
        
        this.state = {
            showMessage:false, 
            message:'',
        }
        
        this.name = React.createRef();
        this.email = React.createRef();
        this.message = React.createRef();

        this.onSubmit = this.onSubmit.bind(this)
        
    }

    onSubmit(e){
        e.preventDefault()
        axios({
            method: 'post',
            url: letapiPosturl,
            headers: {'Authorization':'Token 0448a8246fe20e85693a0dacad933141c38d481a'},
            //headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`},
            data: {
                name: this.name.current.value,
                email: this.email.current.value,
                message:this.message.current.value,
            },
            
            }).then(res=>{
                this.setState({showMessage:true, message:"Thank you for your message, we will get in contact with you soon."})
            }).catch(error=>{
                var message = ''
                if (error.response){
                    for (var field in error.response.data){
                        message = message + '<li>' + field.charAt(0).toUpperCase() + field.slice(1) + ' : ' + error.response.data[field] + "</li>"
    
                    }
                    message = '<ul>' + message + '</ul>'
                    this.setState({showMessage:true,message:message})
                    
                }else{
                    this.setState({showMessage:true,message:"An error occured please contact Commercial Radio Australia for support"})
                }
            });
    }

    render(){

        const display = {
            display: 'block'
        }

        return(
            <div class={(this.props.showContact) ? "modal fade show":"modal fade"}   tabindex="-1" role="dialog" aria-hidden="true"
            style={(this.props.showContact) ? display : null}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Contact Us</h5>
                    <button type="button" class="close"  onClick={this.props.onClickContact} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                        <div class="col-md-12 mb-3">
                            <label for="firstName">Name</label>
                            <input type="text" ref={this.name} class="form-control" id="Name" placeholder="" required=""/>
                            <div class="invalid-feedback">
                            Valid name is required.
                            </div>
                            
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="email">Email</label>
                            <input type="email" ref={this.email} class="form-control" id="email" placeholder="you@example.com" required=""/>
                            <div class="invalid-feedback">
                                Please enter a valid email address for shipping updates.
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label for="messagebox">Message</label>
                            <textarea ref={this.message} class="form-control" id="message"  required=""/>
                            <div class="invalid-feedback">
                                Please enter a valid email address for shipping updates.
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <button onClick={this.onSubmit} className="btn btn-primary">Submit</button>
                        </div>
                        {
                                    (this.state.showMessage) ? <div class="alert alert-primary" role="alert" dangerouslySetInnerHTML={{__html:this.state.message}}>
                                       
                                  </div> : null
                         }
                        
                </div>
                    

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"  onClick={this.props.onClickContact} data-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
        )
    }

}

export default Contact