import React from 'react';
import './stylesheets/dashboard.css'



const FMDashboard = ( {title} ) => (
	<div className="">

			  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
				<h1 className="h2">{title}</h1>
				
			  </div>
				<h1> Dashboard </h1>

			
		
    </div>
);

export default FMDashboard;
