import React from 'react';
import axios from 'axios';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable';
import { HotTable } from '@handsontable/react';
import AsyncSelect from 'react-select/lib/Async';
import queryString from 'query-string';
import Select from 'react-select';
import './stylesheets/DABSubmit.css'

let apiurl = 'https://codesapi.dra.com.au/api/' + 'licencearea/'
let apiDetailurl = 'https://codesapi.dra.com.au/api/' + 'licencedetail/'

//let letapiPosturl = 'http://localhost:9000/api/'+'codesreview/'
let letapiPosturl = 'https://codesapi.dra.com.au/api/'+'codesreview/'


class DABSubmit extends React.Component{
    constructor(props){
        super(props)
        this.state = {
			inputValue: '',
			licenceareas: { results:[] },
			selectedOption: [],
            licencedetails: { results:[]},
            networkOptions:[],
            networkSelected:[],
            ensembleOptions:[ "8A: 195.936 MHz",
                "8B: 197.648 MHz",
                "8C: 199.360 MHz",
                "8D: 201.072 MHz",
                "9A: 202.928 MHz",
                "9B: 204.640 MHz",
                "9C: 206.352 MHz",
                "9D: 208.064 MHz"],
            showResults: false,
            message:'',
            showMessage: false,
			
        }
        this.id = 'hot';
        this.data = [
            ["", "Ford", "Volvo", "Toyota", "Honda"],
            ["2016", 10, 11, 12, 13],
            ["2017", 20, 11, 14, 13],
            ["2018", 30, 15, 12, 13]
          ];
        
        this.hotSettings = {
            colHeaders: [
                'Callsign',
                'Station Label',
                'SId',
                'SubChId',
                'Ensemble',
            ],
            columns: [
                {
                    data: 'callsign',
                    type: 'text',
                },
                {
                    data: 'name',
                    type: 'text',
                },
                {
                    data: 'dabparameter.0.sid',
                    renderer: function(instance, td, row, col, prop, value, cellProperties) {
                        Handsontable.dom.empty(td);
                        if (value != null){
                            td.innerHTML = value.toString(16).toUpperCase();      
                        }                     
                        return td
                    },
                    
                },
                {
                    data: 'dabparameter.0.subch',
                    type: 'text'
                },
                {
                    data: 'dabparameter.0.frequency',
                    type: 'dropdown',
                    source: this.state.ensembleOptions,
                },
    
            ],
            
            contextMenu: {
              items: {
                  
                'row_above': {},
                'row_below': {},
                'separator': Handsontable.plugins.ContextMenu.SEPARATOR,
                'clear_custom': {
                  name: 'Clear all cells',
                  callback: function() {
                    this.clear();
                  }
                }
              }
            }
        }

        this.hotTableComponent = React.createRef();
        this.name = React.createRef();
        this.email = React.createRef();

        this.handleChange = this.handleChange.bind(this)	
        this.handleNetworkChange = this.handleNetworkChange.bind(this)	
        this.onSubmit = this.onSubmit.bind(this)
        this.updateTable = this.updateTable.bind(this)
        this.addRow = this.addRow.bind(this)
    }


    addRow= () =>{
        var length = this.hotTableComponent.current.hotInstance.countRows()
        this.hotTableComponent.current.hotInstance.alter('insert_row',length);
    }


    handleChange = (selectedOption) => {
        this.setState({ selectedOption:selectedOption });

		this.props.history.push(  `/dashboard/tools/dabsubmit?search=${selectedOption.value}`);
		
		axios.get(apiDetailurl+'?search='+selectedOption.value,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ showMessage:false,licencedetails: res.data, showResults: false, networkSelected:[] });
                    this.updateTable();

        })
    }

    handleNetworkChange = (networkSelected) => {
        this.setState({ networkSelected });
        const results = this.state.licencedetails.results
        let rows = null
        let dataTable = []
        
        if (results !== 'undefined'){
                rows = results.map((r,index)=>{
                    let stations = r.stations
                    let dablimits = r.LicenceAreaDABLimit
                    let stationFiltered = stations.filter(function(station){
                        return station.network_name === networkSelected.label
                    });

                    let dablimitFiltered = dablimits.filter(function(dablimit){
                        return dablimit.network.name === networkSelected.label
                    });
                    this.setState({showResults: true,showMessage:false})
                    this.hotTableComponent.current.hotInstance.loadData( stationFiltered);
                    this.hotTableComponent.current.hotInstance.render();
                   
            })
           
        }
    }

    
    
    updateTable = (props) =>{
        const results = this.state.licencedetails.results
        let rows = null
        let dataTable = []
        
        if (results !== 'undefined'){
                rows = results.map((r,index)=>{
                    let networks = r.network
                    let community = networks.filter(function(network){
                        return network.name.toUpperCase() === 'COMMUNITY'
                    });
                    let commercials = networks.filter(function(network){
                        return network.name.toUpperCase() !== 'COMMUNITY'
                    });
            
                    commercials = commercials.sort((a,b)=> a.name > b.name);
            
                    networks = [...commercials, ...community];
                    let networkOptions = []
                    networks.map((network,index)=>{
                        networkOptions.push({value:network.urlname,label:network.name})

                    })
                    
                    this.setState({networkOptions:networkOptions})
                
                    this.hotTableComponent.current.hotInstance.loadData(  r.stations);
            })
           
        }
    }

    componentDidMount(){
		axios.get(apiurl,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ licenceareas: res.data });
	
        }).catch(function(error){
			window.location = '/signin'
		})
        
        let url = this.props.location.search;
		let params = queryString.parse(url);
		if (params.search !== undefined){
			var selected = (params.search).split(',')
			
			for ( var item in selected){
				console.log(item)
				this.state.selectedOption.push({label:selected[item], value:selected[item]});	
			}

			axios.get(apiDetailurl+'?search='+params.search,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
                this.setState({ licencedetails: res.data, showResults: false });
                this.updateTable();

			}).catch(function(error){
                window.location = '/signin'
            })
        }
        
        
    }
    
    
    
    onSubmit(e){
		e.preventDefault()
        axios({
            method: 'post',
            url: letapiPosturl,
            //headers: {'Authorization':'Token a2be974175d5e46d32d92f0a273b29b067a2864a'},
            headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`},
            data: {
                networkurl: this.state.networkSelected.value,
                licencearea: JSON.stringify(this.state.selectedOption),
                name:this.name.current.value,
                email:this.email.current.value,
                table_data: JSON.stringify(this.hotTableComponent.current.hotInstance.getData()),
            },
            
            }).then(res=>{
                this.setState({showMessage:true, showResults:false,message:"Thank you for the submission, we will review the submission and update the records accordingly"})
            }).catch(error=>{
                var message = ''
                if (error.response){
                    for (var field in error.response.data){
                        message = message + field.charAt(0).toUpperCase() + field.slice(1) + ' : ' + error.response.data[field] + "<br/>"
    
                    }
                    this.setState({showMessage:true,message:message})
                    
                }else{
                    this.setState({showMessage:true,message:"An error occured please contact Commercial Radio Australia for support"})
                }
            });
       
	}

    render(){


        
        const filterLicenceArea = (inputValue) =>
		  this.state.licenceareas.results.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
		
		const promiseOptions = inputValue =>
		  new Promise(resolve => {
			setTimeout(() => {
			  resolve(filterLicenceArea(inputValue));
			}, 1);
        });
        
        return(
            <div>
                <div className="card">
                    <div className="card-header">
                        <h1>Update DAB+ services Information</h1>
                    </div>
                </div>
                <hr/>
                <div className="col-sm-12">
                    <div class="form-group">
                        <AsyncSelect
                            options={promiseOptions}
                            onChange={this.handleChange}
                            loadOptions={promiseOptions}
                            value={this.state.selectedOption}
                            menuContainerStyle={{'zIndex': 999}}
                            placeholder = "Start typing to select a licence area"
                        />
					</div>
                    <div class="form-group">
                        <Select
                            value={this.state.networkSelected}
                            onChange={this.handleNetworkChange}
                            options={this.state.networkOptions}
                            menuContainerStyle={{'zIndex': 999}}
                            placeholder = "Select Broadcaster"
                        />
                    </div>
                </div>
                
            
                <hr/>
                
                    
                     
                    <div className="col-sm-12" >
                        <div id="hot-app" >
                            <button onClick={this.addRow} className="btn btn-primary">Add Row</button>
                            <hr/>
                            <HotTable  ref={this.hotTableComponent} id={this.id} settings={this.hotSettings}  stretchH="all" />
                           
                        </div>
                        <hr/>
                        {
                            (this.state.showResults) ?
                                
                                <div>
                                    <div class="col-md-6 mb-3">
                                        <label for="firstName">Name</label>
                                        <input type="text" ref={this.name} class="form-control" id="Name" placeholder="" required=""/>
                                        <div class="invalid-feedback">
                                        Valid name is required.
                                        </div>
                                      
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="email">Email</label>
                                        <input type="email" ref={this.email} class="form-control" id="email" placeholder="you@example.com" required=""/>
                                        <div class="invalid-feedback">
                                            Please enter a valid email address for shipping updates.
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <button onClick={this.onSubmit} className="btn btn-primary">Submit Updates</button>
                                    </div>
                                   
                                </div>
                               
                                : null
                        }
                         {
                                    (this.state.showMessage) ? <div class="alert alert-primary" role="alert" dangerouslySetInnerHTML={{__html:this.state.message}}>
                                      
                                  </div> : null
                         }
                    </div> 
                    
                
            </div>

        )

    }

 

}


export default DABSubmit;