import React, {Component} from 'react';
import './stylesheets/dashboard.css'
import SearchBar from './SearchBar'
import Network from './Network'

class Search extends Component {
	constructor(props) {
		super(props);
	}
	render(){
		console.log(`${this.props.match.url}`)
		return(
			<div className="">
				 <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
												
				</div>

				{
					
					(this.props.location.pathname === `/dashboard/callsign`)?  <SearchBar isCallsign='True' location={this.props.location} history={this.props.history}/> :
					(this.props.location.pathname === `/dashboard/network`) ? <Network /> : 
					<div> file not found </div>
					
				
				}
				
					
				
			</div>
		)
	}
}

export default Search;
