import React, { Component } from 'react';
import './App.css';

import Home from './components/ui/Home'
import CodeTools from './components/ui/CodeTools'
import SignIn from './components/ui/SignIn'
import { Switch, Route } from 'react-router-dom'


const Whoops404 = () => (
	<div className="">
		Page does not exist!
		
    </div>
);


class App extends Component {
  render() {
    return (
	 
      <div className="App">
			<Switch>
				<Route exact path='/' component={Home}/>
        <Route exact path='/about' component={Home}/>
        <Route exact path='/privacy' component={Home}/>
        <Route exact path='/terms' component={Home}/>
        <Route exact path='/userguide' component={Home}/>
        <Route exact path='/signin' component={SignIn}/>
				<Route path='/dashboard' component={CodeTools}/>
				<Route path='*' component={Whoops404}/>
			</Switch>
        
      </div>
    );
  }
}

export default App;
