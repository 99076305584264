import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBroadcastTower,faCity, faDrawPolygon, faCodeBranch, faBook, faFileAlt, faUser, faUserSecret, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { Route, NavLink, Redirect } from 'react-router-dom';

import { Link } from 'react-router-dom'

const cardStyle = {
  maxWidth:'18rem'
};

const Cards = ({url,faIcon, title, description, textclass}) =>(
    <Link  to={url}> 
    <div className={`card ${textclass} mb-3`} style={cardStyle}>
      <div className="card-body">
        <h5><FontAwesomeIcon icon={faIcon}  size="2x"/></h5>
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
    </div>
  </Link>
)


class Dashboard extends React.Component  {
    constructor(props){
      super(props)

    
    }
    render(){
        const cardStyle = {
          maxWidth:'18rem'
        };

        const {match} = this.props
        return(
            <main role="main" className="container">
            <div className="row">
              <div className="card-columns">
                <Cards textclass="text-dark border-primary" 
                      url={`${match.url}/callsign`} 
                      faIcon={faBroadcastTower} 
                      title="Search by Station" 
                      description="Quickly search for station's PI, SId, EId and site information using the station's name/label or callsign." />

                 <Cards textclass="text-dark  border-secondary" 
                      url={`${match.url}/network`} 
                      faIcon={faCity} 
                      title="Search by Broadcaster/Network" 
                      description="List all stations under a broadcaster or network to check PI, SId, EId and frequency information" />

                 <Cards textclass="text-dark border-success" 
                      url={`${match.url}/licencearea`} 
                      faIcon={faDrawPolygon} 
                      title="Search by Licence Area" 
                      description="Search stations by Licence area for frequency, PI, SId, and EId information." />

                 <Cards textclass="text-dark border-danger" 
                      url={`${match.url}/tools/applynew`} 
                      faIcon={faCodeBranch} 
                      title="Apply for FM PI Code" 
                      description="Have a new station with a new frequency? Apply for a new FM PI code." />
               
               <Cards textclass="text-dark border-warning" 
                      url={`${match.url}/DAB/Overview`} 
                      faIcon={faBook} 
                      title="DAB EId/SId Allocation Overview" 
                      description="Complete licence area overview of EId and SId allications based on state." />

                <Cards textclass="text-dark border-info" 
                      url={`${match.url}/tools/daballocation`} 
                      faIcon={faFileAlt} 
                      title="Search Licence Area for DAB EId and SId" 
                      description="Search licence areas for EId and SId allocations for the ensemble and per broadcaster/Network" />
                
                <Cards textclass="text-dark border-light" 
                      url="/terms" 
                      faIcon={faFileAlt} 
                      title="Terms and Conditions" 
                      description="Australian Broadcast Codes website Terms and conditions" />
                <div onClick={this.props.onClickContact}>
                <Cards textclass="text-dark border-dark" 
                      url="#" 
                      faIcon={faUser} 
                      title="Contact" 
                      description="Contact form" />
                </div>
                <Cards textclass="text-dark border-secondary" 
                      url="/privacy" 
                      faIcon={faUserSecret} 
                      title="Privacy Policy" 
                      description="Australian Broadcast Codes website privacy policy" />  
              
                
              </div>
      
              
      
            </div>
           
          </main>
        )
    }
}

export default Dashboard;