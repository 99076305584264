import React from 'react';
import axios from 'axios'
import AsyncSelect from 'react-select/lib/Async';
import StationTable from './StationTable'
import queryString from 'query-string';


let apiurl = 'https://codesapi.dra.com.au/api/' + 'licencearea/'
let apiDetailurl = 'https://codesapi.dra.com.au/api/' + 'licencedetail/'

const EnsembleAllocationRange = (props) => {

    if (props.daballocation.length > 0){
        var results = props.daballocation.map((r,index)=>{
            return(
                <div>
                    <strong>EId: </strong>{r.eid.toString(16).toUpperCase()}
                    <br/><strong>SId RANGE:</strong>{(r.sid_low).toString(16).toUpperCase()} - {(r.sid_high).toString(16).toUpperCase()}
                </div>
            )})
        }else{
            var results = <h6> No DAB allocation</h6>
        }

    return(results)
}

const EnsembleAllocationTable = (props) => {

let rows =<tr> </tr>
const results = props.licences
if (results !== 'undefined'){
    rows = results.map((r,index)=>{
        return(
            <div>
                <div className="card" >
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6">
                            {r.ACMA_name} 
                            </div>
                            <div className="col-md-6">
                                <div className="text-right">
                                        <EnsembleAllocationRange daballocation={r.daballocation}/>
                                
                                </div>
                            </div>
                        </div>
            
                     </div>
                    <div className="card-body">
                        
                        <p className="card-text">
                           
                            {
                                r.network.map(function(t,k){
                                    return (
                                        <div className="row mb-1">
                                            <div className="col-md-12">
                                                <div className="card">
                                                <div className="card-header">
													<div className="row">
                                                        <div className="col-md-6">
															<span className="text-left">
																{t.name}
															</span>
														</div>
														{
															r.LicenceAreaDABLimit.map(function(u,z){
																
																return(
																	
																	(u.network.name === t.name) ?
																	<div className="col-md-6" key={z}>
																		<div className="text-right">
																			<strong>SId RANGE:</strong>{(u.sid_low).toString(16).toUpperCase()} - {(u.sid_high).toString(16).toUpperCase()} | 
																			<strong>SUBCH RANGE:</strong>{(u.subch_low.toString())} - {(u.subch_high.toString())}
																		</div>
																	</div>: null
																	
																	)

																	

															})
                                                                
                                                            
														} 
                                                        </div>
                                                </div>
                                                <table className="table">
                                                {
                                                    r.stations.map(function(y,l){
                                                        return(
                                                        (t.name === y.network_name) ?
                                                        <tr key={l}>
															<td>{ y.name}</td>
															<td>{ y.callsign}</td>
														</tr>: null

                                                        )
                                                    })  
                                                }
                                                </table>
                                                </div>	
                                            </div>											
                                        </div>
                                    )
                                })
                            }
                            
                            
                        </p>
                     </div>
                </div>
                <hr />
            </div>
            

        )
    
    })
}
return (
    <div className="col-sm-12">
        {rows}
    </div>

)


}

class LicenceArea extends React.Component{
    constructor(props){
		super(props)
		this.state = {
			inputValue: '',
			licenceareas: { results:[] },
			selectedOption: [],
			licencedetails: { results:[{stations:[]}]},
			showResults: false,
			
		}
		this.handleChange = this.handleChange.bind(this)	
		this.onSubmit = this.onSubmit.bind(this)
	}
	
	componentDidMount(){
		axios.get(apiurl,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ licenceareas: res.data });
	
		}).catch(function(error){
			window.location = '/signin'
		})

		let url = this.props.location.search;
		let params = queryString.parse(url);
		if (params.search !== undefined){
			var selected = (params.search).split(',')
			
			for ( var item in selected){
				console.log(item)
				this.state.selectedOption.push({label:selected[item], value:selected[item]});	
			}

			axios.get(apiDetailurl+'?search='+params.search,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				this.setState({ licencedetails: res.data, showResults: true });

			}).catch(function(error){
				window.location = '/signin'
			})
		}
	}
	
	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	}
	
	onSubmit(e){
		e.preventDefault()
		var searchstring = ''
		
		
		for ( var selected in this.state.selectedOption){
			searchstring += this.state.selectedOption[selected].value + ","
		}
		searchstring = searchstring.substring(0,searchstring.length-1)  
		this.props.history.push(  `/dashboard/licencearea?search=${searchstring}`);
		axios.get(apiDetailurl+'?search='+searchstring,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ licencedetails: res.data, showResults: true });
	
		})  
		.catch(function(error){
			window.location = '/signin'
		})
	}
		
	render(){
		const filterLicenceArea = (inputValue) =>
		  this.state.licenceareas.results.filter(i =>
			i.label.toLowerCase().includes(inputValue.toLowerCase())
		);
		
		const promiseOptions = inputValue =>
		  new Promise(resolve => {
			setTimeout(() => {
			  resolve(filterLicenceArea(inputValue));
			}, 5);
		});
				
		const handleInputChange = (newValue) => {
			const inputValue = newValue.replace(/\W/g, '');
			this.setState({ inputValue });
			return inputValue;
		  };
		  
		console.log(this.state.licencedetails.results)
		return (
			
			<div>
				<div className="card">
				  <div className="card-header">
					<h1>Search Stations by Licence Area</h1>
				  </div>
				</div>
				<hr/>
				<div className="col-sm-12">

						<form>
						<div className="form-group">
							 <AsyncSelect
								isMulti
								cacheOptions
								defaultOptions
								onChange={this.handleChange}
								loadOptions={promiseOptions}
								value={this.state.selectedOption}
							  />
						</div>
						<button onClick={this.onSubmit} type="submit" class="btn btn-primary">Submit</button>
						</form>
						<hr/>
                        { (this.state.showResults) ? 
							this.state.licencedetails.results.map((licence,i)=>{
								return(
									<div key={i}>
										<h4>{licence.name}</h4>
										<StationTable stations={licence.stations}/>
									</div>
								)
							}) : null
						}
							
					</div>
				
			</div>

		)
		
	}


}
export default LicenceArea