import React from 'react';
import axios from 'axios'
import SearchTable from './SearchTable'
import queryString from 'query-string';

class SearchBar extends React.Component {
		
	state = {
		count: '',
		next: '',
		previous:'',
		results: [],
		searchfield:'',
		showResult: false,
	}		
	
	constructor(props){
		super(props);
		this.submit = this.submit.bind(this);
	}

	componentDidMount(){
		let url = this.props.location.search;
		let params = queryString.parse(url);
		if (params.search !== undefined){
			this.refs.search.value = params.search
			axios.get('https://codesapi.dra.com.au/api/station/?callsign='+params.search,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					
					this.setState( res.data );
					this.setState({ 
							searchfield : params.search,
							showResult: true
						});
			}).catch(function(error){
				window.location = '/signin'
			})
		}
	}
	
	submit(e){
		e.preventDefault()
		console.log('search', this.refs.search.value)
		this.props.history.push( `/dashboard/callsign?search=${this.refs.search.value}`);
		axios.get('https://codesapi.dra.com.au/api/station/?callsign='+this.refs.search.value,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				this.setState( res.data );
				this.setState({ 
						searchfield : this.refs.search.value,
						showResult: true
					});
		})
	}
	
	render() {	
		return (
			<div>
				<div className="card">
					  <div className="card-header">
						<h1>Search By Station</h1>
					  </div>
				</div>
				<div className="card-body">
					<form onSubmit={this.submit}>
					  <div className="form-group">
						<input id="callsign" type="text" className="form-control" id="searchInput" aria-describedby="searchHelp" placeholder="Please enter a callsign or station"
							ref = "search"
							/>
						{this.props.isCallsign ? <small id="searchHelp" className="form-text text-muted">Search for a callsign or a station</small> : <small id="searchHelp" className="form-text text-muted">Search for Broadcaster</small>}
						
					  </div>
					  <button type="submit" className="btn btn-primary">Submit</button>
					</form>
				</div>
				{
					this.state.showResult ? 
							<SearchTable results={this.state.results} searchfield={this.state.searchfield}  count={this.state.count}/> : null
					
				}
				

			</div>
			
		);
	
	}

	
}

export default SearchBar;
	




