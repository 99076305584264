import React from 'react';


class Privacy extends React.Component  {

    render(){
        return(
            <main role="main" className="container">
            <div className="row">
              <div className="col-md-12 blog-main">
                <h3 className="pb-3 mb-4 font-italic border-bottom">
                  Privacy to be updated
                </h3>
      
                <div className="blog-post">
                <div class="island"> <h4>
	<strong>CRA PRIVACY POLICY</strong></h4>
<strong>Our Commitment to Privacy</strong><br />
<br />
Commercial Radio Australia respects your privacy and that of other people who use this website (Website), and is committed to protecting your personal information. We will comply with the requirements of the Australian Privacy Principles (APPs) contained in the&nbsp;Privacy Act 1988 (Cth) &nbsp;in the way that we manage that is collect, store and use any “personal information” that we collect about you.&nbsp;<br />
<br />
Personal information means information which identifies you – or renders you reasonably identifiable - as an individual, whether that information is true or not and whether it is recorded in a material form or not. Examples of personal information include your name and gender, home or email address, and contact telephone numbers.<br />
<br />
Sensitive information means personal information that includes information about particularly sensitive matters, such as your race or ethnic origins, political opinions, religious or philosophical beliefs, or sexual orientation. &nbsp;We generally do not ask for sensitive information, but may do so where permitted by law and where it is reasonably necessary for our functions or activities.<br />
<br />
This Privacy Policy explains what type of personal information we collect and how we handle that information. You can read the full details of our Privacy Policy below but the following is a quick summary:<br />
<ul>
	<li>
		We only collect personal information that we require for the business purposes of Commercial Radio Australia, such as events, research, marketing, products and services (both our own and those of other companies that may be of interest to you);</li>
	<li>
		&nbsp;We may collect your name, address, email, phone number, other contact details, age, birthday, gender, photographs/film/audio (where you attend a CRA affiliated event), your interests and opinions about our brands and services, information related to promotions and activities that CRA runs, such as competition entries, job applications, complaints and enquiries.</li>
	<li>
		We will not rent, trade, sell or otherwise disclose your personal information to any third party, other than with your consent or as may be required by law;</li>
	<li>
		We will use reasonable steps to ensure the safe storage of your personal information; and</li>
	<li>
		To find out what personal information we hold about you or to have that personal information changed or deleted, please contact us via email to the Privacy Officer at&nbsp;<a href="mailto:privacy@commercialradio.com.au">privacy@commercialradio.com.au</a></li>
</ul>
<strong>How do we Collect your Personal Information and Why?</strong><br />
<br />
Generally, we collect your personal information to provide you with products, services and events, and to manage our relationship with you.<br />
<br />
We may collect personal information - such as name, address, telephone number, email, gender, age, address, interests, opinions - from you:
<ul>
	<li>
		as part of the membership application process;</li>
	<li>
		when you apply for a job advertised on the Website;</li>
	<li>
		as part of your use of or participation in CRA affiliated products, services and events;</li>
	<li>
		when you make inquiries or provide feedback via the Website; and</li>
	<li>
		when you enter a competition.</li>
</ul>
<strong>How we will use the Personal Information we Collect?</strong><br />
<br />
Generally we will only use the personal information we collect for the primary purpose for which that information has been collected or for a closely related business purpose.<br />
<br />
We will only use your personal information for other purposes if we receive your consent, or if we are permitted or required to by law.<br />
<br />
We will not use your personal information to send you information about our activities or our members unless you expressly indicate that you want ro receive such information.<br />
<br />
<strong>Who will we Disclose your Personal information to?</strong><br />
<br />
The personal information we collect about you will be accessible by relevant staff within Commercial Radio Australia. Such information may be accessible by our IT contractors as well.<br />
<br />
From time to time, it may be necessary to disclose the personal information we hold about you to our service providers, contractors, members or business associates to enable us to provide relevant services to you. Those services will be linked to the purposes for which we collected that personal information.<br />
<br />
We may also disclose the personal information we hold about you to third parties with your consent or if we are allowed to or required to do so by law.<br />
<br />
<strong>How we will Handle the Personal Information we have About you?</strong><br />
<br />
We will take reasonable steps to ensure the secure storage of any personal information provided to us via the Website. Such personal information will be stored in a secure electronic environment accessible only to people working at or for Commercial Radio Australia. That information may also be printed and stored in paper form.<br />
<br />
All staff and contractors of Commercial Radio Australia are required to maintain the confidentiality of all personal information to which they have access.<br />
<br />
We will only retain the personal information we hold about you for as long as we need it for our purposes. However, we may retain that information for a longer period if required to for legal purposes.<br />
<br />
<strong>Cookies and Session Tracking</strong><br />
<br />
We are constantly trying to improve the services we provide via the Website. Therefore we use “cookies” which are a form of tracking technology which is stored on your computer’s hard drive when you visit the Website. The cookies we use record certain information such as which parts of the Website you visit.<br />
<br />
We use cookies to track the popularity each page on the Website and to track the overall volume and direction of traffic. We don’t analyse or record your individual choices or activities. Instead, we use aggregated information to tell us about the general viewing trends of visitors to the Website. By observing these general trends, we can design the Website to make it more appealing and user friendly.<br />
<br />
Most web browsers are set up to accept cookies by default. However, if you do not wish to receive cookies you may be able to configure your browser settings to either prompt you first or to reject cookies. However, please note that by setting up your browser to reject cookies, you may not be able to view all the information on the Website.<br />
We may use third parties to collect information on the website, including through the use of cookies (flash and non flash) and web beacons.&nbsp; Further information about cookies and opt out options may be accessed at www.youronlinechoices.com.au.<br />
<br />
<strong>How you can Access your Personal Information?</strong><br />
<br />
Under the Privacy Act, you generally have a right to seek access to the personal information that we hold about you. However, there are times when we are required or allowed to withhold access to some or all of this information.<br />
<br />
If you believe the personal information we hold about you is wrong or out of date, you have a right to ask us to correct it.<br />
<br />
Before you can exercise these rights, we will need to ask you to provide some form(s) of identification so that we can verify that you are the person to whom the personal information relates.<br />
&nbsp;&nbsp;<br />
<strong>How to Contact us?</strong><br />
<br />
If you have any questions about this Privacy Policy or you wish to access your personal information or make a complaint about our handling of your personal information, please contact our Privacy Officer by either:<br />
<ul>
	<li>
		Email:&nbsp;<a href="mailto:privacy@commercialradio.com.au">privacy@commercialradio.com.au</a>;</li>
	<li>
		Telephone (during business hours): (02) 9281 6577;</li>
	<li>
		Fax: (02) 9281 6599; or</li>
	<li>
		Post: The Privacy Officer, Commercial Radio Australia, Level 5, 88 Foveaux Street, Surry Hills, NSW, 2010.</li>
</ul>
<strong>Last Updated: January 2018</strong><br />
<br />




</div>
                </div>
      
                
      
                   
              </div>
      
              
      
            </div>
           
          </main>
        )
    }
}

export default Privacy;