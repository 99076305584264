import React from 'react';
import axios from 'axios'
import './stylesheets/DABOverview.css'
import { ScaleLoader } from 'react-spinners';

let apiurl = 'https://codesapi.dra.com.au/api/' + 'daballocation/'

class DABOverview extends React.Component{
		constructor(props){
			super(props)
			this.state = {
				nsw_data: { results:[] },
				qld_data: { results:[] },
				sa_nt_data: {results:[]},
				wa_data: {results:[]},
				vic_tas_data: {results:[]},
				
			}
			
		}
	
	
		componentDidMount(){
			axios.get(apiurl+'?limit=62',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ nsw_data: res.data });
					
			}).catch(function(error){
				window.location = '/signin'
			})
			axios.get(apiurl+'?limit=58&offset=62',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ qld_data: res.data });
					
			}).catch(function(error){
				window.location = '/signin'
			})
			axios.get(apiurl+'?limit=58&offset=120',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ sa_nt_data: res.data });
					
			}).catch(function(error){
				window.location = '/signin'
			})
			axios.get(apiurl+'?limit=58&offset=178',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ wa_data: res.data });
					
			}).catch(function(error){
				window.location = '/signin'
			})
			axios.get(apiurl+'?limit=58&offset=236',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState({ vic_tas_data: res.data });
					
			}).catch(function(error){
				window.location = '/signin'
			})
		}
		
		
	
		render(){
			const ShowResult = (props) => (
				<div className="col">
					<table id="daboverview" className="table table-striped">
						<thead>
							<tr><h6>{props.title}</h6></tr>
							<tr>
								<th scope="col">Licence</th>
								<th scope="col">EId/SId Start</th>
								<th scope="col">SId END</th>
	
							</tr>
						</thead>
						<tbody key='index'>
					
					
						{	
							props.results.map((item,k)=>
								<tr key={k}>
									<td>
										{item.licence_area}
									</td>
									<td>
										{(item.sid_low).toString(16).toUpperCase()}
									</td>
									<td>
										{(item.sid_high).toString(16).toUpperCase()}
									</td>
								</tr>
							)
						}
						</tbody>
					</table>
				</div>
			)
			return (
				<div>
					<div className="card">
					  <div className="card-header">
						<h1>DAB SId Allocations Overview</h1>
					  </div>
					</div>
				
					<div className=".col-12">
						<div className="row">
							<ShowResult results={this.state.nsw_data.results} title='Regional NSW' />
							<ShowResult results={this.state.vic_tas_data.results} title='Regional VIC & TAS' />
							<ShowResult results={this.state.qld_data.results} title='Regional QLD'/>
							<ShowResult results={this.state.sa_nt_data.results} title='Regional SA & NT'/>
							<ShowResult results={this.state.wa_data.results} title='Regional WA' />
							
						</div>
					</div>
				</div>
			)
			
		}
}


export default DABOverview;
