import React from 'react';
import { Link } from 'react-router-dom'

const StationTable = (props) => {

	let rows =<tr> </tr>
	var results = props.stations
	if (results !== null){
		rows = results
		.sort((a,b) => a.callsign > b.callsign)
		.map((r,index)=>{
			return(
					
					<tr key={index}>
						<td><Link to={`/dashboard/callsign/${r.callsign}/${r.id}/`}>{r.callsign}</Link></td>
						<td>{r.name}</td>
						<td>
						{(r.fmparameter.length > 0) ?
							r.fmparameter[0].frequency: null		
						}	
						</td>
						<td>
						{(r.fmparameter.length > 0) ?
							r.fmparameter[0].picodecurrent: null		
						}	
						</td>
						<td>
						{(r.fmparameter.length > 0) ?
							r.fmparameter[0].picodegen: null		
						}	
						</td>
						<td>
						{(r.amparameter.length > 0) ?
							r.amparameter[0].frequency: null		
						}	
						</td>
						<td>
						{(r.dabparameter.length > 0) ?
							r.dabparameter[0].frequency: null		
						}	
						</td>
						<td>
						{(r.dabparameter.length > 0) ?
							r.dabparameter[0].sid.toString(16).toUpperCase(): null		
						}	
						</td>
						<td>
						{(r.dabparameter.length > 0) ?
							r.dabparameter[0].subch: null		
						}	
						</td>		
					</tr>

			)
		
		})
	}
	return (
		<div>
			<table className="table table-striped">
				<thead>
					<tr>
						<th class="clickable" onClick={()=>props.sortBy('callsign')} scope="col">Callsign</th>
						<th class="clickable" onClick={()=>props.sortBy('name')} scope="col">Station Name</th>
						<th scope="col">FM Frequency</th>
						<th scope="col">PI Code Current</th>
						<th scope="col">PI Code Recommended</th>
						<th scope="col">AM Frequency</th>		
						<th scope="col">DAB Frequency</th>		
						<th scope="col">Service ID</th>		
						<th scope="col">Sub-channel</th>				
					</tr>
				</thead>
				<tbody key='index'>
					{rows}
				</tbody>
			</table>
		</div>
	
	)
	

}

export default StationTable