import React from 'react';



class UserGuide extends React.Component  {

    render(){
        return(
            <main role="main" className="container">
            <div className="row">
              <div className="col-md-12 blog-main">
                <h3 className="pb-3 mb-4 font-italic border-bottom">
                  How to guide
                </h3>
      
                <div className="blog-post">
                  <h2 className="blog-post-title">How to Guide TO BE UPDATED</h2>
                  <p className="blog-post-meta">October 30, 2018 by <a href="#">Wilson</a></p>
      
                  <p>Australian Broadcast codes website (codes.dra.com.au) is maintained by Commercial Radio Australia Ltd (ABN <a href="https://abr.business.gov.au/ABN/View?abn=52059731467">52 059 731 467</a>). This website is to provide guidance on allocation on FM PI codes and DAB SId and EId allocations to the Australian radio industry. Conforming to the guidance notes will hopefully reduce duplication and clashes of FM PI codes and, DAB EId and SId in Australia.</p>

<p>FM PI codes are pre-allocated by state and frequency. We recommend the use of the recommended FM PI codes but if it cannot be changed please provide the &quot;live&quot; PI code that is currently used.</p>

<p>DAB EId and SId ranges have been pre-allocated according to guidance notes. If you have any enquires please contact Commercial Radio Australia.</p>

<p>This page will provide a guide to how to use the website and what functionalilty it can provide.</p>

<h2>Front Page</h2>

<p>The front page allows access to the user guide, the DAB SI/EId and FM PI codes guidence notes. The menu will provide access to About, Privacy, Terms and Contact pages. The dashboard is where access to the tools become available.</p>

<p><img alt="" src="https://codesapi.dra.com.au/media/uploads/2018/11/02/screencapture-localhost-3000-2018-11-02-10_13_25.png" className="img-fluid"/></p>

<h2>Dashboard</h2>

<p>The Dashboard is currently restricted access and will require a username and password. Please contact Wilson (wilson.ng@commercialradio.com.au) from Commercial Radio Australia for access.</p>

<h3>Functions available</h3>

<strong>Search by Station</strong>

<p>Quickly search for station&#39;s PI, SId, EID and site information using the station&#39;s name/label or callsign.</p>

<strong>Search by Broadcaster/Network.</strong>

<p>List all stations under a broadcaster or network to check PI, SId, EId and frequency information.</p>

<strong>Search By Licence Area</strong>

<p>Search stations by licence area for frequency, PI, SId and EId information.</p>

<strong>Apply for FM PI Code</strong>

<p>Apply for a new FM PI code for a station with a new callsign and frequency. If it is an existing station with a callsign, please use search by station.</p>

<strong>DAB EId/SId Allocation</strong>

<p>Complete licence area overview of EId and SId allocations based on state.</p>

<strong>Search Licence area for DAB EId and SId</strong>

<p>Search licence areas for EId and SId allocations according to broadcaster/network within the licence area.</p>

<p><img src="https://codesapi.dra.com.au/media/uploads/2018/11/02/image-20181102104015-1.png"  className="img-fluid"/></p>
                </div>
      
                
      
                   
              </div>
      
              
      
            </div>
           
          </main>
        )
    }
}

export default UserGuide;