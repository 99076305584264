import React from 'react';



class About extends React.Component  {

    render(){
        return(
            <main role="main" className="container">
            <div className="row">
              <div className="col-md-12 blog-main">
                <h3 className="pb-3 mb-4 font-italic border-bottom">
                  About
                </h3>
      
                <div className="blog-post">
                  
                  <div class="island">
                    <p style={{"text-align":"justify"}}>Commercial Radio Australia Ltd is the national industry body representing Australia&#39;s commercial radio industry. CRA pursues a range of issues on behalf of its member stations. The staff of Commercial Radio Australia specialise in areas such as media legislation and regulation, industrial relations, human resources, marketing, events management, advertising advice, audience surveys and research and digital radio.</p>

                    <p style={{"text-align":"justify"}}>Commercial radio broadcasting began in 1925. Commercial Radio Australia Ltd was established in 1930 as the Federation of Australian Radio Broadcasters (FARB). FARB became an incorporated company in 1993 and changed its name to Commercial Radio Australia Limited in 2002. FARB was founded with 33 members. Today Commercial Radio Australia Ltd has 260 members, representing 99% of commercial radio licensees on air.</p>

                    <p style={{"text-align":"justify"}}>In recent times there has been a consolidation of radio station ownership. Commercial member radio stations are now owned by over 30 operators, with 80 per cent of the stations formed into 12 networks.</p>

                    <p style={{"text-align":"justify"}}>The Board of Commercial Radio Australia is elected by the industry at the annual general meeting and includes a chairman and a vice chairman (regional) and up to 9 other directors representing metropolitan and regional commercial radio broadcasters.</p>

                    <p style={{"text-align":"justify"}}>Membership is open to Australian companies that hold a section 36 or section 39 commercial radio broadcasting service licence that entitles them to provide radio services in the broadcasting services bands.</p>

                    <p style={{"text-align":"justify"}}>Formal applications for membership can be lodged with Commercial Radio Australia Ltd. Please mail applications to:</p>

                    <p style={{"text-align":"justify"}}>Commercial Radio Australia Ltd<br />
                    Level 5, 88 Foveaux Street<br />
                    Surry Hills NSW 2010</p>
                    </div>
                </div>
      
                
      
                   
              </div>
      
              
      
            </div>
           
          </main>
        )
    }
}

export default About;