import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './stylesheets/home.css'
import NavBar from './NavBar'
import Contact from './Contact'
import About from './About'
import Privacy from './Privacy'
import Terms from './Terms'
import UserGuide from './UserGuide'

const style_box = {
	width: '80%',
	height: '300px',
	borderRadius: '21px 21px 0 0'
	
};

class Home extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			showContact: false,
		}
		this.onClickContact = this.onClickContact.bind(this)
	}

	onClickContact(e){
		this.setState(state => ({ showContact: !state.showContact}));
	}


	render(){
		return(
			<div className="">
			<NavBar onClickContact={this.onClickContact} />
			{
				(this.props.location.pathname === "/") ? 
				<div>
					<div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
						<div className="col-md-5 p-lg-5 mx-auto my-5">
							<h1 className="display-4 font-weight-normal">Australian Radio Broadcast Codes</h1>
							<p className="lead font-weight-normal">Allocations and registration of FM PI Codes and Digital Radio Broadcasts EId/SId in Australia</p>
							<div class="form-group">
								<Link className="btn btn-outline-secondary btn-lg" to="/userguide" role="button" aria-pressed="true"> User Guide</Link>
							</div>
							<div class="form-group">
								<Link className="btn btn-outline-secondary btn-lg" target="_blank" to="/attachments/CRA-ENG-BN075%20v1.0%20-%20DAB%2B%20EId%20and%20SId.pdf" role="button" aria-pressed="true">DAB SI/EId Document</Link>
							</div>
							<div class="form-group">	
								<Link className="btn btn-outline-secondary btn-lg" target="_blank" to="/attachments/CRA-ENG-BN072%20v0.99b%20-%20FM%20PI%20and%20DAB%20SId%20codes.pdf" role="button" aria-pressed="true">FM PI Codes Document</Link>
							</div>
						</div>
						<div class="product-device box-shadow d-none d-md-block"></div>
						<div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
					</div>
					<div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
						<div className="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden">
						<div className="my-3 py-3">
						<h2 className="display-5">FM Broadcasting</h2>
							<p className="lead">Program Identification(PI) codes</p>
						</div>
						<div className="bg-light box-shadow mx-auto" style={style_box}></div>
						</div>
						<div className="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
						<div className="my-3 p-3">
							<h2 className="display-5">Digital Radio Broadcasting</h2>
							<p className="lead">Ensemble and Service Identification codes</p>
						</div>
						<div className="bg-dark box-shadow mx-auto" style={style_box}></div>
						</div>
					</div> 
				</div>
				:(this.props.location.pathname === "/about") ? <About />
				:(this.props.location.pathname === "/privacy") ? <Privacy />
				:(this.props.location.pathname === "/terms") ? <Terms />
				:(this.props.location.pathname === "/userguide") ? <UserGuide />
				: null
			}
			<Contact showContact={this.state.showContact}  onClickContact={this.onClickContact}/>

    </div>


		)
			
		
	}
}


export default Home;
