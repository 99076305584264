import React from 'react';
import { Link } from 'react-router-dom'


class NavBar extends React.Component{
  constructor(props){
      super(props)

  }
  


  render(){
    return(
      <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <h5 className="my-0 mr-md-auto font-weight-normal"></h5>
      <nav className="my-2 my-md-0 mr-md-3">
        <Link className="p-2 text-dark" to="/">Home</Link>
		    <Link className="p-2 text-dark" to="/signin">Dashboard</Link>
        <Link className="p-2 text-dark" to="/about">About</Link>
        <Link className="p-2 text-dark" to="/privacy">Privacy</Link>
        <Link className="p-2 text-dark" to="/terms">Terms</Link>
        <Link className="p-2 text-dark" to="#" onClick={this.props.onClickContact}>Contact</Link>
      </nav>
      
    </div>
    )
  }
}

export default NavBar;
