import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SignIn.css'
import { connect } from 'react-redux'
import { loginUser } from '../../redux/actions'

class SignIn extends React.Component{
    constructor(props){
        super(props)
       
        this.handleClick = this.handleClick.bind(this)
    }
    

    componentDidMount() {
        const { isAuthenticated} = this.props
        if(isAuthenticated){
            this.props.history.push('/dashboard')
        }
		document.body.classList.add('text-center')
	}
	
	componentWillUnmount() {
		document.body.classList.remove('text-center')
    }
    
    handleClick(e){
        e.preventDefault()
        const { dispatch } = this.props
        const username = this.refs.username
        const password = this.refs.password
        const creds={username: username.value.trim(), password: password.value.trim()}
        dispatch(loginUser(creds))
    }

    render(){
        const { isAuthenticated, errorMessage } = this.props
        return(
            <div>
                <span className="mb-4"  alt="" width="72" height="72"> <FontAwesomeIcon icon="stroopwafel" size="7x"/></span>
               <form className="form-signin">
                {isAuthenticated ?  this.props.history.push('/dashboard'):null}
                {(errorMessage) ? 
                    <div className="alert alert-danger" role="alert">
                       {errorMessage}
                    </div>:
                    null
                }
                <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                <label htmlFor="inputEmail" className="sr-only">Email address</label>
                <input type="text" ref='username' className="form-control" placeholder="User name" required autoFocus=""/>
                <label htmlFor="inputPassword" className="sr-only">Password</label>
                <input type="password" ref='password' className="form-control" placeholder="Password" required/>
                <button onClick={this.handleClick} className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                <p className="mt-5 mb-3 text-muted">© 2018</p>
                </form>
            </div>
        )
    }

}

SignIn.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}

function mapStateToProps(state) {

    const { auth } = state
    const { isAuthenticated, errorMessage } = auth
  
    return {
      isAuthenticated,
      errorMessage
    }
}

export default connect(mapStateToProps)(SignIn)