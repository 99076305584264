// actions.js
import axios from 'axios'

var apiURL = 'https://codesapi.dra.com.au/'
//var apiURL = 'http://localhost:9000/'
// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

function receiveLogin(token) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    token
  }
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

function requestLogout() {
    return {
      type: LOGOUT_REQUEST,
      isFetching: true,
      isAuthenticated: true
    }
  }
  
  function receiveLogout() {
    return {
      type: LOGOUT_SUCCESS,
      isFetching: false,
      isAuthenticated: false
    }
  }

export function loginUser(creds) {

    return dispatch => {
        dispatch(requestLogin(creds))
        axios.defaults.withCredentials = true;
        return axios.post(apiURL+'api-token-auth/', { username:`${creds.username}`, password: `${creds.password}` })
        .then(function(response){
            localStorage.setItem('id_token', response.data.token)
         
            dispatch(receiveLogin(response.data.token))
        })
        .catch(function(error){
            if (error.response){
                //console.log(error.response.data.non_field_errors)
                dispatch(loginError(error.response.data.non_field_errors[0]))
            }else{
              dispatch(loginError('Error Logging into server'))  
            }
        });
            
          


    }

}

export function logoutUser() {
    return dispatch => {
      dispatch(requestLogout())
      localStorage.removeItem('id_token')
      dispatch(receiveLogout())
    }
  }