import React, {Component} from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import LoadingDefault from './LoadingDefault'
import { ScaleLoader } from 'react-spinners';

let lastScrollY = false;
let apiurl = 'https://codesapi.dra.com.au/api/network/'

class Network extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: '',
			next: '',
			previous:'',
			results: [],
			lastScroll: false,
			loading: true,

		}
						
		
	}
	
	componentDidMount(){
		this.state.lastScroll = false;
		window.addEventListener('scroll', this.handleScroll);
		axios.get(apiurl,{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				this.setState( res.data );
				this.setState({loading:false})
				
		}).catch(function(error){
			window.location = '/signin'
		})
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
	
	handleScroll = () => {

		if ( !this.state.lastScroll)
		{
			this.setState({lastScroll:true})
			if (this.state.next != null){
				axios.get(this.state.next, {headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
					this.setState( {
								next: res.data.next,
								results: [...this.state.results,...res.data.results]
								,
					}).catch(function(error){
						window.location = '/signin'
					});
					this.setState({lastScroll:true})
				})
			}
				
		
		}
		
		
	};
	
	
	render(){
		const rows = this.state.results
			.sort((a,b) => a.name > b.name)
			.map( (r, key) => {
			return(
				<tr key={key}>
					<td>{r.name}</td>
					<td>{r.stations_count}</td>
					<td><Link to={`/dashboard/network/${r.urlname}`}>View</Link></td>
				
				</tr>
			)
		})
		
		return(
			<div>
				<div className="card">
					  <div className="card-header">
						<h1>Search By Broadcaster/Network</h1>
							<ScaleLoader
								sizeUnit={"px"}
								size={50}
								color={'#000'}
								loading={this.state.loading}
								/>
					  </div>
				</div>
				<table className="table table-striped">
					<thead>
						<tr>
							<th scope="col">Network</th>
							<th scope="col">Station Count</th>
							<th scope="col">View</th>		
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>

			</div>
		)
	}
}

export default Network;
