import React from 'react';


class Terms extends React.Component  {

    render(){
        return(
            <main role="main" className="container">
            <div className="row">
              <div className="col-md-12 blog-main">
                <h3 className="pb-3 mb-4 font-italic border-bottom">
                  Terms and Conditions to be updated
                </h3>
      
                <div className="blog-post">
                <div class="island"> <h3>
	<strong>TERMS AND CONDITIONS OF USE</strong></h3>
These terms and conditions (<strong>Terms</strong>) govern your use of our websites, social media pages, web pages and website (<strong>Website</strong>), mobile sites (<strong>mSites</strong>), mobile applications (<strong>mApps</strong>) and all related content (<strong>Content</strong>) (collectively “<strong>Digital Platforms</strong>”) you acknowledge that you have read and understood these Terms and agree to be bound by them.<br />
<br />
The Digital Platforms are owned and operated by Commercial Radio Australia Ltd (ABN 52 059 731 467)&nbsp;referred to below as “<strong>we</strong>”, “<strong>us</strong>” or <strong>CRA</strong>.<br />
<br />
By accessing and using the Digital Platforms, you will be taken to have agreed to these Terms.&nbsp; Please note that we may, in our sole discretion, amend the Terms from time to time.&nbsp; Your continued use of our Digital Platforms after any such changes are posted constitutes your acceptance of the revised terms and conditions. You are responsible for keeping yourself updated on any changes.<br />
<br />
The Terms incorporate any other terms listed on the Digital Platforms.&nbsp;&nbsp;<br />
<br />
<p>
	<strong>1.&nbsp;&nbsp;&nbsp; </strong><strong>Information on the digital platforms is not legal or professional advice</strong><br />
	<br />
	We represent commercial broadcasting stations in Australia and provide a wide range of services to our members including advocacy, education and training on most issues that affect the industry, including digital radio.<br />
	<br />
	The Digital Platforms contain general information about the development, launch, technology and use of radio in Australia.&nbsp; We may alter or remove this information at any time.<br />
	<br />
	Any such information is not intended, and should not be taken, to be legal or professional advice.&nbsp; We endeavour to take care in producing or compiling this information, however we do not guarantee that it is accurate, current, comprehensive or free from errors or omissions.&nbsp; You should seek your own legal or professional advice before acting upon or relying on any of the information found on the Digital Platforms.<br />
	<br />
	<strong>2.&nbsp;&nbsp;&nbsp; </strong><strong>Access</strong><br />
	<br />
	Access and use of the Digital Platforms is only available to people aged 18 years or older or with parent or legal guardian approval.<br />
	<br />
	<strong>3.&nbsp;&nbsp;&nbsp; </strong><strong>Services</strong><strong> and fees</strong><br />
	<br />
	We reserve the right at any time to charge or amend any fees payable by you and to vary or amend the terms and conditions which cover such fees for access or use of our Digital Platforms.<br />
	<br />
	<strong>4.&nbsp;&nbsp;&nbsp; </strong><strong>&nbsp;Licence to use the&nbsp;Digital Platforms</strong><br />
	<br />
	We grant you a limited, non-exclusive, non-transferable licence to access and use the Digital Platforms solely for your personal, non-commercial purposes.<br />
	<br />
	The Digital Platforms and the material on the Digital Platforms (including any text, images, designs, applications, software, music, logos, graphics, audio or audio-visual material) are protected by Australian and international copyright and trade mark laws.&nbsp; All material is protected in Australia by the <em>Copyright Act 1968</em>.&nbsp; This material may not be copied, reproduced, adapted, transmitted or distributed, nor may it be modified or reposted to other sites in any manner. Unless otherwise indicated, this material is the property of CRA.&nbsp; Some content may be subject to the intellectual property rights of third party providers.<br />
	<br />
	In using the Digital Platforms, you must not do anything that interferes with or breaches the above laws or the proprietary rights of CRA or any other third party in the Digital Platforms and the material on the Digital Platforms.<br />
	<br />
	All rights not expressly granted by these Terms are reserved.<br />
	<br />
	You agree that you will not commercialise any aspect of the Digital Platforms or use our trademarks or logos, unless prior written consent is obtained from us.<br />
	<br />
	Subject to any applicable law, we may revoke the permissions referred to above at any time and may suspend or deny you access to all or any part of the Digital Platforms without notice.<br />
	<br />
	<strong>5.&nbsp;&nbsp;&nbsp; </strong><strong>Registration</strong><br />
	<br />
	You may be required to register with us in order to access or use the Digital Platforms. You may also be required to register with us when you Post User Content (having the definition set out below). Where you are required to register:<br />
	<br />
	a) you must safeguard any user name and password which we provide to you;<br />
	<br />
	b) where your user name and/or password is specific to you, you must not allow anyone else to use your username and/or password;<br />
	<br />
	c) you agree to immediately notify us of any unauthorised use of your user name and/or password or any breach of security of which you become aware;<br />
	<br />
	d) you may cancel your registration at any time at any time by notifying us;<br />
	<br />
	e) we reserve the right to discontinue or cancel your registration if you do not use the Digital Platforms for an extended period of time, if you breach any of these terms and conditions or any applicable law or if we conclude that your conduct impacts on our name or reputation or violates our rights or those of another party.<br />
	<br />
	<strong>6.&nbsp;&nbsp;&nbsp; </strong><strong>User Content</strong></p>
The Digital Platforms may contain material uploaded, posted, emailed or otherwise electronically transmitted (Posted) by users of the Digital Platforms including you (User Content). Any User Content you Post may, at our sole discretion, form part of the Content available on the Digital Platforms.<br />
<br />
When you Post User Content on our Digital Platforms you grant us an irrevocable, perpetual, non-exclusive, worldwide, royalty free licence to, and to authorise and sublicense other persons to:<br />
<br />
a) access and examine that User Content;<br />
<br />
b) use, reproduce, modify, adapt, create derivative works from, incorporate in other works, publicly perform, publicly communicate and commercially exploit that User Content, including but not limited to, on and in connection with any current or future services which include (without limitation) any communication platforms in any media;<br />
<p>
	c) move, remove or disable access to User Content which we consider to breach any law or to be otherwise unacceptable;<br />
	<br />
	d) use your name and likeness (and the names and likenesses of any other persons or entities who own or are associated with the User Content you Post), for any advertising and promotional purposes in connection with the User Content.<br />
	<br />
	You unconditionally and irrevocably consent to all or any acts or omission by us or any person authorised by us in relation to the User Content which may infringe any moral rights you hold, including but not limited to your right:<br />
	<br />
	a) to be identified as the author of any User Content;<br />
	<br />
	b) not to have your authorship falsely attributed; and<br />
	<br />
	c) to integrity of authorship, in particular, the right not to have your User Content subjected to derogatory treatment. To the extent such consent is ineffective in any jurisdiction, you unconditionally and irrevocably waive all moral rights you hold in the User Content in favour of us.<br />
	<br />
	You acknowledge that we may remove any User Content Posted by you and that we have no responsibility or liability for the deletion or failure to store any communications or content Posted on the Digital Platforms.<br />
	<br />
	You are solely responsible for any User Content Posted by you and for obtaining any necessary licences, consents, clearances, permissions and releases from third parties so that you are able to grant us the rights to the User Content you Post as set out in these terms and conditions. In the case of audio/visual recordings, you understand that licences, consents, clearances and permissions may be required in respect of separate copyrights in the audio/visual recording and the underlying works (such as the musical, literary or dramatic work subsisting in the audio/visual recording) and, among other things, moral rights and performers’ consents.<br />
	<br />
	In using the Digital Platforms, you must not:<br />
	<br />
	a) violate any applicable laws;<br />
	<br />
	b) impersonate any person;<br />
	<br />
	c) Post any User Content or use the Service to communicate any material that:<br />
	<br />
	I. infringes the intellectual property rights or any other rights of any third party;<br />
	<br />
	II. is, or could reasonably be expected to be, unlawful, harmful, threatening, abusive, untrue, inaccurate, misleading, invasive of another's privacy, confidential, harassing, defamatory, slanderous, vulgar, obscene, hateful, racist, embarrassing or otherwise objectionable to any other person or entity;<br />
	<br />
	III. contains any unsanctioned advertising, promotional materials, or any other forms of unsanctioned solicitation, including without limitation junk mail, spam, chain letters, or any unsolicited mass distribution of email;<br />
	<br />
	IV. contains a survey, contest, or pyramid scheme; or<br />
	<br />
	V. contains an improper question;<br />
	<br />
	d) stalk, harass or otherwise harm others;<br />
	<br />
	e) distribute viruses, corrupted files, or any other similar software or programs that may damage the operation of any computer hardware or software;<br />
	<br />
	f) collect or store personal data about other users of the Digital Platforms; or<br />
	<br />
	g) engage in any other conduct that inhibits any other person from using or enjoying the Digital Platforms.<br />
	<br />
	<strong>7. &nbsp; &nbsp;</strong><strong>Your representations and warranties</strong><br />
	<br />
	You represent and warrant that:<br />
	<br />
	a) your use of the Digital Platforms complies in all respects with these terms and conditions; and<br />
	<br />
	b) you will not use the Digital Platforms in any manner that is unlawful, infringes the rights of any party or breaches these terms and conditions.<br />
	<br />
	<strong>8.&nbsp;&nbsp;&nbsp; </strong><strong>Indemnity</strong><br />
	<br />
	You agree to defend, indemnify and hold harmless us, our officers, directors, employees, agents, subcontractors, licensors, and suppliers, from and against all claims, actions, demands, liabilities and settlements, including, without limitation, reasonable legal and accounting fees, arising in connection with your use of the Digital Platforms (including, without limitation, your Posting of any User Content), a breach of any of your representations and warranties or your breach of any of these terms and conditions.<br />
	<br />
	<strong>9.&nbsp;&nbsp;&nbsp; </strong><strong>Use is at your own risk</strong><br />
	<br />
	You use the Digital Platforms entirely at your own risk. You must evaluate and bear all risks associated with the use of any Content, including reliance on the accuracy, completeness or usefulness of any Content. In particular:&nbsp;<br />
	<br />
	a) we endeavour to provide convenient and functional Digital Platforms, but we do not guarantee that that your requirements will be met or that your access and use will be uninterrupted and error free or that the Digital Platforms or the server that operates them are free of viruses or other harmful components; and<br />
	<br />
	b) while we may attempt to keep information on the Digital Platforms current and accurate, we do not make any warranties or representations about the currency and accuracy of any such information.&nbsp;<br />
	<br />
	You must take your own precautions to ensure that your access to the Digital Platforms does not expose you to the risk of viruses, malicious computer codes or other forms of interference which may damage your computer system or data. If your use of the Digital Platforms results in the need for servicing or replacing property, material, equipment or data, we will not be responsible for such costs under any circumstances.<br />
	<br />
	Without limiting the above provisions, you have rights under the Australian Consumer Law and other similar legislation which cannot be excluded, restricted or modified by us. These rights include a statutory guarantee that any services provided by us will be rendered with due care and skill and that any goods will be of acceptable quality. These terms and conditions do not exclude, restrict or modify those statutory rights in any way. However, to the extent that it is permitted by law to do so, we make no representations or warranties, express or implied, other than the Australian Consumer Law, regarding the quality or suitability of the Digital Platforms under these terms and conditions and will not be responsible for breach of any such implied terms.<br />
	<br />
	You must ensure that your access to the Digital Platforms (and any linked websites or online material or data) is not illegal or prohibited by laws which apply to you.<br />
	<br />
	<strong>10.&nbsp;&nbsp;&nbsp; </strong><strong>Limitation of Liability</strong><br />
	<br />
	To the maximum extent permitted by law:<br />
	<br />
	a) we have no liability to you, whether under contract for breach of these terms, in negligence, in any other tort, in equity or for any other common law or statutory cause of action arising in relation to these terms, the Digital Platforms or any linked websites or online material or data.<br />
	<br />
	b) we will not be liable for any direct, incidental, consequential or indirect damages, loss or corruption of data, loss of profits, goodwill, bargain or opportunity, loss of anticipated savings or any other similar or analogous loss resulting from your access to, or use of, or inability to use Digital Platforms whether based on warranty, contract, tort, negligence, in equity or any other legal theory, and whether or not we know of the possibility of such damage; and<br />
	<br />
	c) our liability for breach of any implied warranty or condition which cannot be excluded is limited at our option to the following:<br />
	<br />
	A. in the case of goods:<br />
	<br />
	i. the replacement of the goods or the supply of equivalent goods;<br />
	<br />
	ii. the repair of such goods;<br />
	<br />
	iii. the payment of the cost of replacing the goods or acquiring equivalent goods; or<br />
	<br />
	iv. the payment of the cost of having the goods repaired; and<br />
	<br />
	B. in the case of services:<br />
	<br />
	i. the supply of the services again; or<br />
	<br />
	ii. the payment of the cost of having the services supplied again.<br />
	<br />
	Subject to our obligations under any implied conditions and warranties referred to in paragraph (a)(iii), our maximum aggregate liability for all claims under or relating to these terms and conditions or their subject matter, whether in contract, tort (including without limitation negligence), in equity, under statute, under an indemnity or on any other basis, is limited to A$100. In calculating our aggregate liability under this clause, the parties must include any amounts paid or the value of any goods or services replaced, repaired or supplied by us for a breach of the implied conditions and warranties referred to under clause 10.1.<br />
	<br />
	<strong>11.&nbsp;&nbsp;&nbsp; </strong><strong>Variation of the Digital </strong><strong>Platforms</strong><br />
	<br />
	You acknowledge that we may vary, modify or discontinue, temporarily or permanently, any or all of the Digital Platforms and you agree that we are not liable to you or any third party for such variation, modification or discontinuance.<br />
	<br />
	<strong>12.&nbsp;&nbsp;&nbsp; </strong><strong>Links and advertisements</strong><br />
	<br />
	The Digital Platforms may contain links to other sites that are owned by third parties. These links are provided solely for your convenience and do not indicate either express or implied endorsement by us of the products or services that are provided by that site. When accessing third party sites and using their products and services you agree and undertake to do so at your own risk.<br />
	<br />
	For the avoidance of any doubt, we hereby acknowledge that:<br />
	<br />
	(a) the Digital Platforms are in no way sponsored, endorsed or administered by, or associated with Apple, Facebook, Google and/or Twitter; and<br />
	<br />
	(b) any information provided by a user in connection with the Digital Platforms is provided to CRA and not to Apple, Facebook, Google and/or Twitter; and<br />
	<br />
	(c) any questions, comments or complaints regarding the Digital Platforms must be directed to CRA, not to Apple, Facebook, Google and/or Twitter.<br />
	<br />
	<strong>13.&nbsp;&nbsp;&nbsp; </strong><strong>General</strong><br />
	<br />
	If any part of these terms and conditions is held to be unenforceable, the unenforceable part is to be given effect to the greatest extent possible and the remainder will remain in full force and effect.<br />
	<br />
	Any disclaimers or limitations of liability in these terms and conditions do not purport to exclude liability arising under statute if, and to the extent, such liability cannot be lawfully excluded.<br />
	<br />
	We may assign any of our rights under these terms and conditions without prior notice to you.<br />
	<br />
	These terms and conditions are governed by the laws of NSW, Australia, and you irrevocably submit to the exclusive jurisdiction of the courts of NSW, Australia.<br />
	<br />
	These terms and conditions and our privacy policy constitute the entire agreement between us and you in relation to the Digital Platforms and supersede all other (prior or&nbsp;contemporaneous) communications or displays whether electronic, oral, or written, between us and you in relation to the Digital Platforms.<br />
	<br />
	Your use of the Digital Platforms is conducted electronically and you agree that we may communicate with you electronically for all aspects of your use of the Platforms, including sending you electronic notices.<br />
	<br />
	The operation of these terms and conditions will survive cancellation of your registration or termination of your ability to access the Digital Platforms.<br />
	<br />
	<strong>14.&nbsp;&nbsp;&nbsp; </strong><strong>Privacy</strong><br />
	We are committed to the protection of your personal information and meeting the standards set out in the Privacy Act 1988 (Cth) (the Act) and the Australian Privacy Principles (APPs). Our privacy policy can be viewed at www.commercialradio.com.au.<br />
	<br />
	If you have a complaint in relation to the collection and handling of your personal information, or if you believe that we have not complied with this Privacy Policy or the Act, please contact our Privacy Officer via the details provided below. We will review all complaints received and the Privacy Officer will respond to the complainant. We will take any privacy complaint seriously, and we aim to resolve all complaints in a timely and efficient manner. We request that you cooperate with us during this process and provide us with relevant information we may require.<br />
	<br />
	We expect our procedures will deal fairly and promptly with your complaint. However, if you remain dissatisfied, you can also make a formal complaint to the Office of the Australian Information Commissioner (which is the regulator responsible for privacy in Australia).<br />
	<br />
	For all privacy inquiries and complaints, please contact CRA’s Privacy Officer by mail at Commercial Radio Australia, 5/88 Foveaux Street, Surry Hills, NSW 2010&nbsp;or phone on (02) 9281 6577.<br />
	<br />
	<strong>Last Updated: January 2018</strong></p>




</div>
                </div>
      
                
      
                   
              </div>
      
              
      
            </div>
           
          </main>
        )
    }
}

export default Terms;