import React from 'react';
import { Link } from 'react-router-dom'


const SearchTable = (props) => {
	
	const count = props.count
	const searchfield = props.searchfield
	const results = props.results
	const rows = results.map( (r, key) => (
		<tr key={key}>
			<td><Link to={`/dashboard/callsign/${r.callsign}/${r.id}/`}>{r.callsign}</Link></td>
			<td>{r.network_name}</td>
			<td>{r.name}</td>
		</tr>
	))

	return (
		<div>
			<h2> {count} search results {searchfield}</h2>
			<table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Callsign</th>
						<th scope="col">Network</th>
						<th scope="col">On Air Id</th>		
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		</div>
		
	
	)

}


export default SearchTable;
	



