import React from 'react';
import { Link } from 'react-router-dom'
import './stylesheets/dashboard.css'
import { Switch, Route,NavLink,Redirect } from 'react-router-dom'
import FMDashboard from './FMDashboard'
import Search from './Search'
import CallsignDetail from './CallsignDetail'
import NetworkDetail from './NetworkDetail'
import PIAddNew from './PIAddNew'
import DABOverview from './DABOverview'
import DABAllocation from './DABAllocation'
import LicenceArea from './LicenceArea'
import PropTypes from 'prop-types';
import { logoutUser } from '../../redux/actions'
import { connect } from 'react-redux'
import Dashboard from './Dashboard'
import DABSubmit from './DABSubmit'
import FMPISubmit from './FMPISubmit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBroadcastTower,faCity, faDrawPolygon, faCodeBranch, faBook, faFileAlt, faUser, faUserSecret, faListAlt } from '@fortawesome/free-solid-svg-icons'
import Contact from './Contact'

class CodeTools extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			showContact: false,
		}

		this.onClickSignOff = this.onClickSignOff.bind(this)
		this.onClickContact = this.onClickContact.bind(this)
	}

	componentWillMount() {
			const { isAuthenticated} = this.props
			
	}

	onClickContact(e){
		this.setState(state => ({ showContact: !state.showContact}));
	}



	onClickSignOff(e){
		e.preventDefault()
		const { dispatch } = this.props
		dispatch(logoutUser())
		window.location = '/'
				
	
}

	render(){
		const {match} = this.props

		return(
			( this.props.isAuthenticated) ?
			<div className="">
			<nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
			  <a className="navbar-brand col-sm-3 col-md-2 mr-0" href="#">Australia Radio Broadcast Codes</a>
			 
			  <ul className="navbar-nav px-3">
				<li className="nav-item text-nowrap">
					<button className="btn btn-outline-success my-2 my-sm-0" onClick={this.onClickSignOff}>Sign Out</button>
				</li>
			  </ul>
			</nav>
		
			<nav className="col-md-2 d-none d-md-block bg-light sidebar">
			  <div className="sidebar-sticky">
				<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				  <span>Station Searches</span>
				  <a className="d-flex align-items-center text-muted" href="#">
					<span data-feather="plus-circle"></span>
				  </a>
				</h6>
				
				<ul className="nav flex-column">
				  <li className="nav-item">
					<Link className="nav-link active" to={`${match.url}`}>
						 Dashboard
					</Link>
				  </li>
				  <li className="nav-item">
					<Link className="nav-link"  to={`${match.url}/callsign`}>
					  <span data-feather="file"></span>
					  	<FontAwesomeIcon icon={faBroadcastTower}  size="1x"/> Search By Station
					</Link>
				  </li>
				  <li className="nav-item">
					<Link className="nav-link" to={`${match.url}/network`}>
					  <span data-feather="shopping-cart"></span>
					  <FontAwesomeIcon icon={faCity}  size="1x"/> Search By Broadcaster/Network
					</Link>
				  </li>
					<li className="nav-item">
					<Link className="nav-link" to={`${match.url}/licencearea`}>
					  <span data-feather="file-text"></span>
					  <FontAwesomeIcon icon={faDrawPolygon}  size="1x"/> Search by Licence Area
					</Link>
				  </li>
				 
				  
				 
				</ul>

				<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				  <span>Tools</span>
				  <a className="d-flex align-items-center text-muted" href="#">
					<span data-feather="plus-circle"></span>
				  </a>
				</h6>
				<ul className="nav flex-column mb-2">
					
				 
				  <li className="nav-item">
					<Link className="nav-link" to={`${match.url}/tools/applynew`}>
					  <span data-feather="bar-chart-2"></span>
					  <FontAwesomeIcon icon={faCodeBranch}  size="1x"/> Apply for PI Code
					</Link>
				  </li>
					<li className="nav-item">
					<Link className="nav-link" to={`${match.url}/tools/fmpisubmit`}>
					  <span data-feather="bar-chart-2"></span>
					  <FontAwesomeIcon icon={faCodeBranch}  size="1x"/> Update Existing FM PI codes
					</Link>
				  </li>

					<li className="nav-item">
					<Link className="nav-link" to={`${match.url}/DAB/overview`}>
					  <span data-feather="file-text"></span>
					  <FontAwesomeIcon icon={faBook}  size="1x"/> DAB Allocation Overview
					</Link>
				  </li>

					<li className="nav-item">
						<Link className="nav-link" to={`${match.url}/tools/daballocation`}>
							<span data-feather="bar-chart-2"></span>
							<FontAwesomeIcon icon={faFileAlt}  size="1x"/> Search Licence Area for DAB EId and SId
						</Link>
					</li>

					<li className="nav-item">
						<Link className="nav-link" to={`${match.url}/tools/dabsubmit`}>
							<span data-feather="bar-chart-2"></span>
							<FontAwesomeIcon icon={faFileAlt}  size="1x"/> Update DAB+ SId and SubCH
						</Link>
					</li>
				
				</ul>
				<h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
				  <span>Additional</span>
				  <a className="d-flex align-items-center text-muted" href="#">
					<span data-feather="plus-circle"></span>
				  </a>
				</h6>
				<ul className="nav flex-column mb-2">
					<li className="nav-item">
					<Link className="nav-link" to='/about'>
					  <span data-feather="file-text"></span>
					  <FontAwesomeIcon icon={faBroadcastTower}  size="1x"/> About
					</Link>
				  </li>
					
					<li className="nav-item">
						<Link className="nav-link" to='/privacy'>
							<span data-feather="bar-chart-2"></span>
							<FontAwesomeIcon icon={faUserSecret}  size="1x"/> Privacy
						</Link>
					</li>
					
					<li className="nav-item">
						<Link className="nav-link" to='/terms'>
							<span data-feather="bar-chart-2"></span>
							<FontAwesomeIcon icon={faListAlt}  size="1x"/> Terms and Conditions
						</Link>
					</li>

					<li className="nav-item">
						<Link className="nav-link" to='#' onClick={this.onClickContact}>
							<span data-feather="bar-chart-2"></span>
							<FontAwesomeIcon icon={faUser}  size="1x"/> Contact
						</Link>
					</li>
					</ul>
			  </div>

				
			</nav>

			<main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
			{
			(this.props.location.pathname === "/dashboard") ? 
				<Dashboard match={match} onClickContact={this.onClickContact}/>
				:null
			}
			<Switch>
				<Route exact path={`${match.url}/FM`} render={()=><FMDashboard title='Dashboard'/>}/>
				<Route exact path={`${match.url}/callsign`} component= {Search}/>
				<Route path={`${match.url}/callsign/:callsign/:id`} component={CallsignDetail}/>
				<Route exact path={`${match.url}/network`} component={Search}/>
				<Route exact path={`${match.url}/network/:networkurl`} component={NetworkDetail}/>
				<Route exact path={`${match.url}/tools/applynew`} component={PIAddNew}/>
				<Route exact path={`${match.url}/tools/fmpisubmit`} component={FMPISubmit}/>
				<Route exact path={`${match.url}/tools/daballocation`} component={DABAllocation}/>
				<Route exact path={`${match.url}/tools/dabsubmit`} component={DABSubmit}/>
				<Route exact path={`${match.url}/licencearea`} component={LicenceArea}/>
				<Route exact path={`${match.url}/DAB/overview`} component={DABOverview}/>
				<Route path='/DAB/licencearea/:licence/:id' component={CallsignDetail}/>
				<Route exact path='/DAB/overview' component={DABOverview}/>
				<Route exact path='/DAB/network' component={DABOverview}/>
				<Route exact path='/DAB/network/:networkurl' component={DABOverview}/>
				
			</Switch>  
			<Contact showContact={this.state.showContact}  onClickContact={this.onClickContact}/>
			</main>

			
		
    </div>:  <Redirect to={{ pathname: '/signin', state: { from: this.props.location } }} />

		)
	}

}




CodeTools.propTypes = {
	dispatch: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string,
}

function mapStateToProps(state) {

	const { auth } = state
	const { isAuthenticated, errorMessage } = auth

	return {
		isAuthenticated,
		errorMessage
	}
}

export default connect(mapStateToProps)(CodeTools);
