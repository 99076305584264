import React, {Component} from 'react';
import axios from 'axios'

import './stylesheets/FMAddNew.css'



let apiurl = 'https://codesapi.dra.com.au/api/' + 'fmparameter/'
//let letapiPosturl = 'http://localhost:9000/api/'+'codesreview/'

let letapiPosturl = 'https://codesapi.dra.com.au/api/'+'codesreview/'

class PIAddNew extends Component  {
	
	constructor(props){
		super(props);
		this.state = {
			symbol: "1",
			frequency_response:"",
			error: "",
			Inputfrequency:"88.1",
			showResults: false,
			showMessage:false,
			message:''
			
		}
		this.onInputChange = this.onInputChange.bind(this);
		this.frequencySearch = this.frequencySearch.bind(this);
		this.callsign = React.createRef()
		this.stationname = React.createRef()
		this.name = React.createRef()
		this.email = React.createRef()
		this.onSubmit = this.onSubmit.bind(this)
		this.licencearea  = React.createRef()
	}
	
	
	onInputChange(e)
	{
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		this.setState({
				[name]: value
			});


	}

	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: "smooth" });
	}
	
	frequencySearch(e){
		e.preventDefault()
		var frequency = this.state.Inputfrequency
		var res = frequency.replace(".","")
		var lastthree = res.substr(res.length - 3);
		axios.get(apiurl+this.state.symbol + lastthree+'/',{headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`}}).then(res=>{
				var {picodegen} = res.data 	
				this.setState({
					frequency_response: picodegen,
					showResults: true
				});
				this.scrollToBottom()
		})
		
		
	}
	

	onSubmit(e){
		e.preventDefault()
	
        axios({
            method: 'post',
            url: letapiPosturl,
            //headers: {'Authorization':'Token a2be974175d5e46d32d92f0a273b29b067a2864a'},
            headers: {'Authorization':`Token ${localStorage.getItem('id_token')}`},
            data: {
                networkurl: 'New PI Code addition',
                licencearea: this.licencearea.current.value,
                name:this.name.current.value,
                email:this.email.current.value,
                table_data: this.callsign.current.value + ' and ' + this.stationname.current.value ,
            },
            
            }).then(res=>{
                this.setState({showMessage:true, showResults:false,message:"Thank you for the submission, we will review the submission and update the records accordingly"})
            }).catch(error=>{
                var message = ''
                if (error.response){
                    for (var field in error.response.data){
                        message = message + field.charAt(0).toUpperCase() + field.slice(1) + ' : ' + error.response.data[field] + "<br/>"
    
                    }
                    this.setState({showMessage:true,message:message})
                    
                }else{
                    this.setState({showMessage:true,message:"An error occured please contact Commercial Radio Australia for support"})
                }
            }); ;
       
	}
	
		
	render(){
		const austates = [
			{'symbol': 1, 'short':'ACT'},
			{'symbol': 2,'short':'NSW'},
			{'symbol': 4,'short':'QLD'},
			{'symbol': 8,'short':'NT'},
			{'symbol': 5,'short':'SA'},
			{'symbol': 7,'short':'TAS'},
			{'symbol': 3,'short':'VIC'},
			{'symbol': 6,'short':'WA'},
	
		]
		
		const ShowResult = () => (
			<div className="form-group" >
			  <div class="card">
				  <div class="card-body">
					Available PI Code: {this.state.frequency_response}
				  </div>
			  </div>
			</div>
		)
		return(
			<div>
				<div className="card">
					  <div className="card-header">
						<h1>Apply for a new PI code</h1>
					  </div>
				</div>
				 <div className="card-body">
						<p>The  PI assignment scheme proposed constructs the PI code based on a combination of the state number, as shown in [3] plus the final 3 digits of the transmitter frequency, e.g. for 2MMM in Sydney on 104.9MHz this would be 2 + 049 = 2049.  This is defined as the base PI code for that FM frequency. </p>
						
						<p>If the same frequency is used within a state then the service with the alphabetically lowest call sign shall be assigned the base PI code unless that PI code is already assigned. The next call sign in alphabetic order shall then be assigned the final digit of 0xA, unless that PI code is used. Assignment shall continue in this manner until the final digit is assigned the code of 0xF, at which time the second final digit assignment shall revert to the hex value 0xA and the final digit start at 0 rising to F. If this range is insufficient then the second final digit continues to 0xB and the final digit again increases in the range 0x0 – F </p>
						
						<h4>Some examples the assignment of PI codes </h4>
						<p>FM frequency 104.9MHz in NSW would proceed according to the following priority order list:</p> 
						<p>2049, 204A, 204B, 204C, 204D, 204E, 204F, 20A0 – 20AF, 20B0 – 20BF etc </p> 
						<p>FM frequency 93.5MHz in VIC would proceed according to the following priority order list:</p>
						<p>3935, 393A, 393B, 393C, 393D, 393E, 393F, 39A0 – 39AF etc
						</p>
				</div>
				<div className="card">
					<div className="card-body">
						<form>
						  <div className="form-group">
							<label for="Inputfrequency">Frequency of new service</label>
							<input type="number" min="88.1" max="107.9" step="0.2" className="form-control" name="Inputfrequency" aria-describedby="frequencyHelp" placeholder="Enter frequency" value={this.state.Inputfrequency} onChange={this.onInputChange}/>
							<small id="frequencyHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
						  </div>
						  <div className="form-group">
							<label for="symbol">State of new broadcast</label>
							<select value={this.state.symbol} class="form-control" name="symbol" onChange={this.onInputChange} >
							{
							  austates.map((state,k)=>
								<option key={k} value={state.symbol}>{state.short}</option>)
							}
							 </select>
						  </div>
							{	this.state.showResults? <ShowResult /> : <button onClick={this.frequencySearch} className="btn btn-primary">Submit</button>}
							{
								(this.state.showResults) ?
										
									<div className="card">
									<div className="card-body">
												<h2>Submit for Review</h2>
												<hr />
												<div class="col-md-6 mb-3">
														<label for="firstName">Licence Area</label>
														<input type="text" ref={this.licencearea} class="form-control" id="licencearea" placeholder="" required=""/>
														<div class="invalid-feedback">
														Valid name is required.
														</div>
													
												</div>
												<div class="col-md-6 mb-3">
														<label for="callsign">Callsign</label>
														<input type="text" ref={this.callsign} class="form-control" id="callsign" placeholder="" required=""/>
														<div class="invalid-feedback">
														Valid name is required.
														</div>
													
												</div>
												
												<div class="col-md-6 mb-3">
														<label for="stationname">Station Name</label>
														<input type="text" ref={this.stationname} class="form-control" id="stationname" placeholder="" required=""/>
														<div class="invalid-feedback">
														Valid name is required.
														</div>
													
												</div>
												<div class="col-md-6 mb-3">
														<label for="Name">Name</label>
														<input type="text" ref={this.name} class="form-control" id="Name" placeholder="" required=""/>
														<div class="invalid-feedback">
														Valid name is required.
														</div>
													
												</div>
												<div class="col-md-6 mb-3">
														<label for="email">Email</label>
														<input type="email" ref={this.email} class="form-control" id="email" placeholder="you@example.com" required=""/>
														<div class="invalid-feedback">
																Please enter a valid email address for shipping updates.
														</div>
												</div>
												<div class="col-md-6 mb-3">
														<button onClick={this.onSubmit} className="btn btn-primary">Submit for Review</button>
												</div>
											
											
												
										</div>
										</div>
										: null
										
							}
							{
											(this.state.showMessage) ?<div> <hr /> <div class="alert alert-primary" role="alert">
													{this.state.message}
										</div> </div>: null
											}
						  
						</form>
						<div style={{ float:"left", clear: "both" }}
														ref={(el) => { this.messagesEnd = el; }}>
												</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PIAddNew